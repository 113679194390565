var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRefresh,
              expression: "showRefresh",
            },
          ],
          staticClass: "right-menu",
        },
        [
          _c(
            "el-checkbox",
            {
              staticClass: "refresh-btn",
              attrs: { value: _vm.refreshDefault },
              on: { change: _vm.setRefresh },
            },
            [_vm._v("定时刷新（每30s）")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "vertical-bar" }, [_vm._v("|")]),
          _vm._v(" "),
          _c("span", [_vm._v("上次更新时间：" + _vm._s(_vm.refreshDate))]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-refresh" },
              on: { click: _vm.setRefreshAction },
            },
            [_vm._v("刷新")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCouponErr
        ? _c(
            "div",
            { staticClass: "right-menu" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    title: "驳回原因",
                    placement: "bottom-end",
                    width: "300",
                    trigger: "click",
                  },
                },
                [
                  _c("div", [_vm._v(_vm._s(_vm.couponErr))]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { color: "#F51B38" },
                      attrs: { slot: "reference", type: "text" },
                      slot: "reference",
                    },
                    [
                      _c("CircleTag", { attrs: { type: "worning" } }),
                      _vm._v("驳回原因\n      "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }