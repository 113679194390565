import Cookies from 'js-cookie';
var ComplexDrugList = 'Complex-Drug-List'; // 组合药品草稿列表
var PopSciArticleList = 'Pop-Sci-Article-List'; // 科普文章草稿列表
var SingleDrugList = 'Single-Drug-List'; // 单个药品草稿列表

var StoreSingleDrugList = 'Store-Single-Drug-List'; // 店铺单个药品草稿列表
var StoreComplexDrugList = 'Store-Complex-Drug-List'; // 店铺组合药品草稿列表

export function getComplexDrug() {
  return Cookies.get(ComplexDrugList) || '[]';
}
export function setComplexDrug(list) {
  return Cookies.set(ComplexDrugList, list);
}
export function getPopSciArticleList() {
  return Cookies.get(PopSciArticleList) || '[]';
}
export function setPopSciArticleList(list) {
  return Cookies.set(PopSciArticleList, list);
}
export function getSingleDrug() {
  return Cookies.get(SingleDrugList) || '[]';
}
export function setSingleDrug(list) {
  return Cookies.set(SingleDrugList, list);
}
export function getStoreSingleDrugList() {
  return Cookies.get(StoreSingleDrugList) || '[]';
}
export function setStoreSingleDrugList(list) {
  return Cookies.set(StoreSingleDrugList, list);
}
export function getStoreComplexDrugList() {
  return Cookies.get(StoreComplexDrugList) || '[]';
}
export function setStoreComplexDrugList(list) {
  return Cookies.set(StoreComplexDrugList, list);
}