import Layout from '@/layout';
var tradeRouter = {
  path: '/trade',
  component: Layout,
  redirect: '/trade/medical/medicalList',
  name: 'trade',
  meta: {
    title: '交易管理',
    icon: 'trade'
  },
  children: [{
    path: 'medical',
    name: 'medical',
    meta: {
      title: '药品订单'
    },
    alwaysShow: true,
    redirect: '/trade/medical/medicalList',
    component: function component() {
      return import('@/views/trade/order/medical/index');
    },
    children: [{
      path: 'medicalList',
      name: 'tradeOrderMedical',
      component: function component() {
        return import('@/views/trade/order/medical/listv2');
      },
      meta: {
        title: '订单列表'
      }
    }, {
      path: 'medicalList/detail',
      component: function component() {
        return import('@/views/trade/order/medical/detail');
      },
      name: 'tradeOrderMedicalDetail',
      meta: {
        title: '药品订单详情'
      },
      hidden: true
    }, {
      path: 'afterSalesList',
      name: 'tradeOrderAfterSales',
      component: function component() {
        return import('@/views/trade/order/medical/afterSalesList');
      },
      meta: {
        title: '售后处理'
      }
    }, {
      path: 'afterSalesList/detail',
      name: 'tradeOrderAfterSalesDetail',
      component: function component() {
        return import('@/views/trade/order/medical/afterSalesDetail');
      },
      meta: {
        title: '售后详情'
      },
      hidden: true
    }
    // {
    //     path: 'ediList',
    //     name: 'tradeOrderEdi',
    //     component: () => import('@/views/trade/order/medical/ediList'),
    //     meta: { title: 'EDI订单列表' },
    // }
    ]
  }, {
    path: 'serviceOrder',
    name: 'tradeOrderServiceOrder',
    meta: {
      title: '服务包订单'
    },
    alwaysShow: true,
    redirect: '/trade/serviceOrder/dnaOrder',
    component: function component() {
      return import('@/views/trade/order/serviceOrder/index');
    },
    children: [{
      path: 'dnaOrder',
      name: 'tradeOrderServiceOrderDnaOrder',
      component: function component() {
        return import('@/views/trade/order/serviceOrder/dna/order');
      },
      meta: {
        title: '诺异安订单列表'
      }
    }, {
      path: 'dnaEdit/:id',
      component: function component() {
        return import('@/views/trade/order/serviceOrder/record/index');
      },
      name: 'tradeOrderServiceOrderDnaEdit',
      meta: {
        title: '记录详情',
        noCache: true,
        activeMenu: '/trade/serviceOrder/dna/order'
      },
      hidden: true
    }, {
      path: 'insurance',
      name: 'tradeOrderServiceOrderDnaInsurance',
      component: function component() {
        return import('@/views/trade/order/serviceOrder/dna/insurance');
      },
      meta: {
        title: '诺异安保险列表'
      }
    }]
  }, {
    path: 'inquiry',
    name: 'tradeOrderInquiry',
    meta: {
      title: '问诊订单'
    },
    redirect: '/trade/inquiry/graphic',
    component: function component() {
      return import('@/views/trade/order/inquiry/index');
    },
    children: [{
      path: 'graphic',
      name: 'tradeOrderInquiryGraphic',
      component: function component() {
        return import('@/views/trade/order/inquiry/graphic');
      },
      meta: {
        title: '图文问诊订单列表'
      }
    }, {
      path: 'complaint/:id',
      component: function component() {
        return import('@/views/trade/order/inquiry/complaint');
      },
      name: 'tradeOrderServiceOrderComplaintEdit',
      meta: {
        title: '投诉内容',
        noCache: true,
        activeMenu: '/trade/inquiry/graphic'
      },
      hidden: true
    }, {
      path: 'video',
      name: 'tradeOrderInquiryVideo',
      component: function component() {
        return import('@/views/trade/order/inquiry/video');
      },
      meta: {
        title: '视频问诊订单列表'
      }
    }, {
      path: 'videoComplaint/:id',
      component: function component() {
        return import('@/views/trade/order/inquiry/videoComplaint');
      },
      name: 'tradeOrderServiceVideoComplaint',
      meta: {
        title: '投诉内容',
        noCache: true,
        activeMenu: '/trade/inquiry/graphic'
      },
      hidden: true
    }]
  }, {
    path: 'jifenduihuan',
    name: 'jifenduihuan',
    meta: {
      title: '积分兑换订单'
    },
    redirect: '/trade/jifenduihuan/duihuanList',
    component: function component() {
      return import('@/views/trade/order/jifenduihuan/index');
    },
    children: [{
      path: 'duihuanList',
      name: 'duihuanList',
      component: function component() {
        return import('@/views/trade/order/jifenduihuan/duihuanList');
      },
      meta: {
        title: '兑换订单列表'
      }
    }, {
      path: 'jifenTuikuanList',
      name: 'jifenTuikuanList',
      component: function component() {
        return import('@/views/trade/order/jifenduihuan/jifenTuikuanList');
      },
      meta: {
        title: '退款记录'
      }
    }]
  }, {
    path: 'prescription',
    name: 'tradeOrderPrescription',
    meta: {
      title: '处方单'
    },
    redirect: '/trade/prescription/list',
    component: function component() {
      return import('@/views/trade/order/prescription/index');
    },
    children: [{
      path: 'list',
      name: 'tradeOrderPrescriptionList',
      component: function component() {
        return import('@/views/trade/order/prescription/list');
      },
      meta: {
        title: '处方单列表'
      }
    }]
  }, {
    path: 'logisticsServerOrder',
    name: 'medicalLogisticsServerOrder',
    meta: {
      title: '服务包',
      groupTitle: '物流'
    },
    alwaysShow: true,
    redirect: '/trade/serverOrder/dna',
    component: function component() {
      return import('@/views/trade/logistics/serverOrder/index');
    },
    children: [{
      path: 'dna',
      name: 'medicalLogisticsServerOrderDna',
      component: function component() {
        return import('@/views/trade/logistics/serverOrder/dna');
      },
      meta: {
        title: '诺异安配送列表'
      }
    }]
  }, {
    path: 'logisticsSetting',
    name: 'medicalLogisticsSetting',
    meta: {
      title: '设置'
    },
    redirect: '/trade/setting/delivery',
    component: function component() {
      return import('@/views/trade/logistics/setting/index');
    },
    alwaysShow: true,
    children: [{
      path: 'delivery',
      name: 'medicalLogisticsSettingDelivery',
      component: function component() {
        return import('@/views/trade/logistics/setting/delivery');
      },
      meta: {
        title: '药品配送管理'
      }
    }, {
      path: 'addressLibrary',
      name: 'addressLibrary',
      component: function component() {
        return import('@/views/trade/logistics/setting/addressLibrary');
      },
      meta: {
        title: '地址库'
      }
    }]
  }, {
    path: 'logisticsSignDoctor',
    name: 'medicalLogisticsSignDoctor',
    meta: {
      title: '佣金发放',
      groupTitle: '佣金发放'
    },
    redirect: '/trade/logisticsSignDoctor/signDoctor',
    // alwaysShow: true,
    component: function component() {
      return import('@/views/trade/logistics/signDoctor/index');
    },
    children: [{
      path: 'signDoctor',
      name: 'medicalLogisticsSignDoctorRewardInfo',
      component: function component() {
        return import('@/views/trade/logistics/signDoctor/index');
      },
      redirect: '/trade/logisticsSignDoctor/signDoctor/signDoctorList',
      meta: {
        title: '签约医生'
      },
      hidden: true,
      children: [{
        path: 'signDoctorList',
        name: 'medicalLogisticsSignDoctorList',
        component: function component() {
          return import('@/views/trade/logistics/signDoctor/signDoctorInfo');
        },
        meta: {
          title: '签约医生列表',
          activeMenu: '/trade/logisticsSignDoctor/signDoctor'
        },
        hidden: true
      }, {
        path: 'SignDoctorBingli',
        name: 'medicalLogisticsSignDoctorBingli',
        component: function component() {
          return import('@/views/trade/logistics/signDoctor/signDoctorBingli');
        },
        meta: {
          title: '病例收集',
          activeMenu: '/trade/logisticsSignDoctor/signDoctor'
        },
        hidden: true
      }, {
        path: 'SignDoctorDetail',
        name: 'medicalLogisticsSignDoctorDetail',
        component: function component() {
          return import('@/views/trade/logistics/signDoctor/signDoctorDetail');
        },
        meta: {
          title: '医生详情',
          activeMenu: '/trade/logisticsSignDoctor/signDoctor'
        },
        hidden: true
      }]
    }]
  }, {
    path: 'logisticsSignDoctorSetting',
    name: 'medicalLogisticsSignDoctorSetting',
    meta: {
      title: '设置'
    },
    redirect: '/trade/signDoctor/signDoctorSetting',
    component: function component() {
      return import('@/views/trade/logistics/signDoctor/signDoctorSetting');
    },
    children: [{
      path: 'SignDoctorSetting',
      name: 'medicalLogisticsSignDoctorInfo',
      component: function component() {
        return import('@/views/trade/logistics/signDoctor/signDoctorSetting');
      },
      meta: {
        title: '设置'
      }
    }]
  }]
};
export default tradeRouter;