import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  rolesViews: function rolesViews(state) {
    return state.user.rolesViews;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  asyncRoutes: function asyncRoutes(state) {
    return state.permission.asyncRoutes;
  },
  showRefresh: function showRefresh(state) {
    return state.settings.showRefresh;
  },
  refreshDefault: function refreshDefault(state) {
    return state.settings.refreshDefault;
  },
  refreshAction: function refreshAction(state) {
    return state.settings.refreshAction;
  },
  refreshDate: function refreshDate(state) {
    return state.settings.refreshDate;
  },
  complexDrugList: function complexDrugList(state) {
    return state.draft.complexDrugList;
  },
  popSciArticleList: function popSciArticleList(state) {
    return state.draft.popSciArticleList;
  },
  showCouponErr: function showCouponErr(state) {
    return state.settings.showCouponErr;
  },
  couponErr: function couponErr(state) {
    return state.settings.couponErr;
  },
  singleDrugList: function singleDrugList(state) {
    return state.draft.singleDrugList;
  },
  userid: function userid(state) {
    return state.user.userid;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  storeSingleDrugList: function storeSingleDrugList(state) {
    return state.draft.storeSingleDrugList;
  },
  storeComplexDrugList: function storeComplexDrugList(state) {
    return state.draft.storeComplexDrugList;
  }
};
export default getters;