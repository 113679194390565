import "core-js/modules/es6.function.name";
import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//

import waterMark from "./utils/waterMark";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
export default {
  name: 'App',
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isRouterAlive: true
    };
  },
  computed: _objectSpread({}, mapGetters(["userInfo", 'userid'])),
  mounted: function mounted() {
    var _this$userInfo;
    if ((_this$userInfo = this.userInfo) !== null && _this$userInfo !== void 0 && _this$userInfo.id) {
      var _this$userInfo2;
      var context = '锦竺' + ((_this$userInfo2 = this.userInfo) === null || _this$userInfo2 === void 0 ? void 0 : _this$userInfo2.name) + dayjs().format('YYYY-MM-DD'); // '25/01/2019'
      waterMark.set(context);
    }
  },
  methods: {
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
};