import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var RoleKey = 'Admin-Role';
var RoleViewKey = 'Admin-RoleView';
var UserID = 'User-ID';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getHywRole() {
  return JSON.parse(Cookies.get(RoleKey) || "[]");
}
export function setHywRole(token) {
  return Cookies.set(RoleKey, token);
}
export function removeHywRole() {
  return Cookies.remove(RoleKey);
}
export function getRoleView() {
  // return Cookies.get(RoleViewKey)
  return localStorage.getItem(RoleViewKey);
}
export function setRoleView(data) {
  // return Cookies.set(RoleViewKey, data)
  return localStorage.setItem(RoleViewKey, data);
}
export function removeRoleView() {
  // return Cookies.remove(RoleViewKey)
  return localStorage.removeItem(RoleViewKey);
}
export function getUserID() {
  return Cookies.get(UserID);
}
export function setUserID(userid) {
  return Cookies.set(UserID, userid);
}
export function removeUserID() {
  return Cookies.remove(UserID);
}
export function setUserInfo(data) {
  // return Cookies.set('USER-INFO', data)
  return localStorage.setItem("USER-INFO", data);
}
export function getUserInfo() {
  // return Cookies.get('USER-INFO')
  return localStorage.getItem("USER-INFO");
}
export function removeUserInfo() {
  // return Cookies.remove('USER-INFO')
  return localStorage.removeItem("USER-INFO");
}