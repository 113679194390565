import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import moment from "moment";
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import ErrorLog from '@/components/ErrorLog';
import CircleTag from '@/components/Tag/CircleTag';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    ErrorLog: ErrorLog,
    CircleTag: CircleTag
  },
  computed: _objectSpread({}, mapGetters(['showRefresh', 'refreshDefault', 'refreshAction', 'refreshDate', 'showCouponErr', 'couponErr'])),
  data: function data() {
    return {};
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    setRefresh: function setRefresh(value) {
      this.$store.dispatch('settings/changeSetting', {
        "key": "refreshDefault",
        "value": value
      });
    },
    setRefreshAction: function setRefreshAction() {
      this.$store.dispatch('settings/changeSetting', {
        "key": "refreshAction",
        "value": this.refreshDate
      });
      this.$store.dispatch('settings/changeSetting', {
        "key": "refreshDate",
        "value": this.getRefreshDate()
      });
    },
    getRefreshDate: function getRefreshDate() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    }
  }
};