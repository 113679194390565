import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data
  });
}
// 4.4.10  获取验证码接口
export function getVerifyCode(query) {
  return request({
    url: '/sms',
    method: 'get',
    params: query
  });
}
// 4.4.10 强制修改密码接口
export function firstUpdatePassword(data) {
  return request({
    url: '/first/update/password',
    method: 'post',
    data: data
  });
}
// 修改密码
export function updatePassword(data) {
  return request({
    url: '/update/password',
    method: 'post',
    data: data
  });
}
// 重置密码
export function resetPassword(data) {
  return request({
    url: '/reset/password',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function getCurrentUserInfo() {
  return request({
    url: '/current_user/info',
    method: 'get'
  });
}
export function fetchAppUserList(query) {
  return request({
    url: '/app_users',
    method: 'get',
    params: query
  });
}
export function exportAppUserList(query) {
  return request({
    url: '/app_users',
    method: 'post',
    params: query
  });
}
export function getAppUserDetail(query) {
  return request({
    url: '/app_users/info',
    method: 'get',
    params: query
  });
}
export function getAppUserCoupons(query) {
  return request({
    url: '/app_users/coupon',
    method: 'get',
    params: query
  });
}
export function fetchUserBlackList(query) {
  return request({
    url: '/blacklists',
    method: 'get',
    params: query
  });
}
export function setUserBlackList(data) {
  return request({
    url: '/blacklists',
    method: 'post',
    data: data
  });
}
export function removeUserBlack(data) {
  return request({
    url: '/blacklists',
    method: 'delete',
    data: data
  });
}
export function setUserOfficialList(id) {
  return request({
    url: "/official/users/".concat(id),
    method: 'put'
  });
}
export function fetchUserIdentity(user_id) {
  return request({
    url: "/users/identity/".concat(user_id),
    method: 'get'
  });
}
export function updateUserIdentity(user_id, data) {
  return request({
    url: "/users/identity/".concat(user_id),
    method: 'post',
    data: data
  });
}

// 优惠券待转化用户列表
export function getUserConversion(data) {
  return request({
    url: '/coupon/change/users',
    method: 'get',
    params: data
  });
}

// 用户分群
export function getUserGroup(data) {
  return request({
    url: '/user/classify',
    method: 'get',
    params: data
  });
}

// 编辑优惠券待转化用户
export function editCouponUser(data) {
  return request({
    url: '/coupon/change/users',
    method: 'put',
    data: data
  });
}

// 删除优惠券待转化用户
export function deleteCouponUser(id) {
  return request({
    url: "/coupon/change/users/".concat(id),
    method: 'delete'
  });
}

// 用户分享/收藏/点赞/评论 的 笔记/文章
export function getNoteOrArticleListByType(data) {
  return request({
    url: '/app_users/content',
    method: 'get',
    params: data
  });
}

// 用户优惠券
export function getCouponList(data) {
  return request({
    url: '/app_users/coupon',
    method: 'get',
    params: data
  });
}

// 查看所有的用户标签GET/cms/r/v1/app_users/tags
export function getUserAllTags(data) {
  return request({
    url: '/app_users/tags',
    method: 'get',
    params: data
  });
}

// 批量添加更新用户标签
export function modifyUserBatchTags(data) {
  return request({
    url: '/app_users/tags',
    method: 'post',
    data: data
  });
}