import Layout from '@/layout';
var marketingRouter = {
  path: '/marketing',
  component: Layout,
  redirect: '/marketing/page',
  name: 'marketing',
  meta: {
    title: '营销中心',
    icon: 'marketing'
  },
  alwaysShow: true,
  children: [{
    path: 'repeatBuyPush',
    name: 'repeatBuyPush',
    meta: {
      title: '复购推送',
      groupTitle: '营销活动'
    },
    redirect: '/marketing/repeatBuyPush/repeatBuyPushList',
    component: function component() {
      return import('@/views/marketing/activity/index');
    },
    children: [{
      path: 'repeatBuyPushList',
      name: 'repeatBuyPushList',
      component: function component() {
        return import('@/views/marketing/activity/repeatBuyPush/repeatBuyPushList');
      },
      meta: {
        title: '复购推送'
      }
    }, {
      path: 'repeatBuyPushResult',
      name: 'repeatBuyPushResult',
      component: function component() {
        return import('@/views/marketing/activity/repeatBuyPush/repeatBuyPushResult');
      },
      meta: {
        title: '复购推送效果'
      },
      hidden: true
    }, {
      path: 'repeatBuyPushSetting',
      name: 'repeatBuyPushSetting',
      component: function component() {
        return import('@/views/marketing/activity/repeatBuyPush/repeatBuyPushSetting');
      },
      meta: {
        title: '复购推送设置'
      },
      hidden: true
    }]
  }, {
    path: 'tool',
    name: 'marketingTool',
    meta: {
      title: '优惠券'
    },
    redirect: '/marketing/tool/couponList',
    component: function component() {
      return import('@/views/marketing/activity/index');
    },
    children: [{
      path: 'couponList',
      name: 'marketingToolCouponList',
      component: function component() {
        return import('@/views/marketing/activity/coupon/list');
      },
      meta: {
        title: '优惠券'
      }
    }, {
      path: 'fullDiscount',
      name: 'marketingToolFullDiscount',
      component: function component() {
        return import('@/views/marketing/activity/coupon/fullDiscount');
      },
      meta: {
        title: '编辑满减优惠券'
      },
      hidden: true
    }, {
      path: 'freeOfPostage',
      name: 'marketingToolFreeOfPostage',
      component: function component() {
        return import('@/views/marketing/activity/coupon/freeOfPostage');
      },
      meta: {
        title: '编辑包邮券'
      },
      hidden: true
    }, {
      path: 'exchange',
      name: 'marketingToolExchange',
      component: function component() {
        return import('@/views/marketing/activity/coupon/exchange');
      },
      meta: {
        title: '编辑兑换券'
      },
      hidden: true
    }, {
      path: 'couponDetail',
      name: 'marketingToolCouponDetail',
      component: function component() {
        return import('@/views/marketing/activity/coupon/couponDetail');
      },
      meta: {
        title: '优惠券详情'
      },
      hidden: true
    }]
  }, {
    path: 'crowdOperation',
    name: 'marketingCrowdOperation',
    component: function component() {
      return import('@/views/marketing/activity/index');
    },
    meta: {
      title: '人群运营'
    },
    redirect: '/marketing/crowdOperation/crowdOperationList',
    children: [{
      path: 'crowdOperationList',
      name: 'marketingCrowdOperationList',
      component: function component() {
        return import('@/views/marketing/activity/crowdOperation/list');
      },
      meta: {
        title: '人群运营'
      }
    }, {
      path: 'crowdOperationDetail',
      name: 'marketingCrowdOperationDetail',
      component: function component() {
        return import('@/views/marketing/activity/crowdOperation/crowdOperationDetail');
      },
      meta: {
        title: '人群运营详情'
      },
      hidden: true
    }, {
      path: 'crowdOperationEffect',
      name: 'marketingCrowdOperationEffect',
      component: function component() {
        return import('@/views/marketing/activity/crowdOperation/crowdOperationEffect');
      },
      meta: {
        title: '运营效果'
      },
      hidden: true
    }]
  }, {
    path: 'page',
    name: 'marketingAdPage',
    component: function component() {
      return import('@/views/marketing/ad/page');
    },
    meta: {
      title: '页面配置',
      groupTitle: '广告位配置'
    }
  }]
};
export default marketingRouter;