import Layout from '@/layout';
var dataRouter = {
  path: '/data',
  component: Layout,
  redirect: '/data/zy/basic',
  name: 'data',
  meta: {
    title: '数据中心',
    icon: 'data'
  },
  children: [{
    path: 'report',
    name: 'operatingReport',
    meta: {
      title: '经营报表',
      groupTitle: '报表'
    },
    redirect: '/data/report/table',
    component: function component() {
      return import('@/views/data/report/index');
    },
    children: [{
      path: 'table',
      name: 'operatingTable',
      component: function component() {
        return import('@/views/data/report/table/index');
      },
      meta: {
        title: '报表分类'
      },
      hidden: true
    }, {
      path: 'deputy',
      name: 'deputyReport',
      component: function component() {
        return import('@/views/data/report/deputy/index');
      },
      meta: {
        title: '专员销售报表'
      },
      hidden: true
    }, {
      path: 'drugOrder',
      name: 'drugOrderReport',
      component: function component() {
        return import('@/views/data/report/drugOrder/index');
      },
      meta: {
        title: '药品订单报表'
      },
      hidden: true
    }]
  }, {
    path: 'zy',
    name: 'dataCollectZY',
    meta: {
      title: '佐愈',
      groupTitle: '病例收集'
    },
    redirect: '/data/zy/basic',
    component: function component() {
      return import('@/views/data/collect/zy/index');
    },
    children: [{
      path: 'basic',
      name: 'dataCollectZYBasic',
      component: function component() {
        return import('@/views/data/collect/zy/basic');
      },
      meta: {
        title: '基础数据报表'
      }
    }, {
      path: 'sales',
      name: 'dataCollectZYSales',
      component: function component() {
        return import('@/views/data/collect/zy/sales');
      },
      meta: {
        title: '销售数据报表'
      }
    }]
  }, {
    path: 'sldx',
    name: 'dataCollectSLDX',
    meta: {
      title: '赛洛多辛'
    },
    redirect: '/data/sldx/schedule',
    component: function component() {
      return import('@/views/data/collect/sldx/index');
    },
    children: [{
      path: 'schedule',
      name: 'dataCollectSLDXSchedule',
      // component: () => import('@/views/error-page/404'),
      component: function component() {
        return import('@/views/data/collect/sldx/schedule');
      },
      meta: {
        title: '项目进度报表'
      }
    }, {
      path: 'report',
      name: 'dataCollectSLDXReport',
      // component: () => import('@/views/error-page/404'),
      component: function component() {
        return import('@/views/data/collect/sldx/report');
      },
      meta: {
        title: '病例数据报表'
      }
    }]
  }, {
    path: 'dna',
    name: 'dataCollectDNA',
    meta: {
      title: '蒂诺安'
    },
    redirect: '/data/dna/schedule',
    component: function component() {
      return import('@/views/data/collect/dna/index');
    },
    children: [{
      path: 'schedule',
      name: 'dataCollectDNASchedule',
      component: function component() {
        return import('@/views/data/collect/dna/schedule');
      },
      meta: {
        title: '项目进度管理'
      }
    }, {
      path: 'caseManagement',
      name: 'dataCollectSLDXCaseManagement',
      component: function component() {
        return import('@/views/data/collect/dna/caseManagement');
      },
      meta: {
        title: '病例管理'
      }
    }, {
      path: 'writeOffAudit',
      name: 'dataCollectWriteOffAudit',
      component: function component() {
        return import('@/views/data/collect/dna/index');
      },
      redirect: '/data/dna/writeOffAudit/writeOffAuditList',
      meta: {
        title: '核销审核'
      },
      children: [{
        path: 'writeOffAuditList',
        name: 'dataCollectWriteOffAuditList',
        component: function component() {
          return import('@/views/data/collect/dna/writeOffAudit');
        },
        meta: {
          title: '核销审核列表',
          activeMenu: '/data/dna/writeOffAudit'
        },
        hidden: true
      }, {
        path: 'writeOffAuditDetail/:id(\\d+)',
        name: 'dataCollectWriteOffAuditDetail',
        component: function component() {
          return import('@/views/data/collect/dna/writeOffDetail');
        },
        meta: {
          title: '审核详情',
          activeMenu: '/data/dna/writeOffAudit'
        },
        hidden: true
      }]
    }]
  }, {
    path: 'analysis',
    name: 'dataAnalysis',
    meta: {
      title: '交易分析',
      groupTitle: '交易分析'
    },
    redirect: '/data/analysis/docIncomeAnalysis',
    component: function component() {
      return import('@/views/data/analysis/index');
    },
    children: [
    //     {
    //     path: 'docIncomeAnalysis',
    //     name: 'dataDocIncomeAnalysis',
    //     component: () => import('@/views/data/analysis/doctorIncome'),
    //     meta: { title: '医生收入' }
    // },
    {
      path: 'docIncomeAnalysis',
      name: 'dataDocIncomeAnalysis',
      component: function component() {
        return import('@/views/data/analysis/doctorIncome');
      },
      meta: {
        title: '医生收入'
      }
    }]
  }
  // ,{
  //     path: 'complexDrugAnalysis',
  //     name: 'dataComplexDrugAnalysis',
  //     component: () => import('@/views/data/analysis/complexDrug'),
  //     meta: { title: '组合药品' }
  // }
  , {
    path: 'couponAnalysis',
    name: 'dataCouponAnalysis',
    component: function component() {
      return import('@/views/data/analysis/coupon');
    },
    meta: {
      title: '优惠券分析'
    }
  }
  // {
  //     path: 'report',
  //     name: 'dataReport',
  //     meta: { title: '我的报表' },
  //     alwaysShow: true,
  //     redirect: '/data/report/done',
  //     component: () => import('@/views/data/report/index'),
  //     children: [{
  //         path: 'done',
  //         name: 'dataReportDone',
  //         component: () => import('@/views/error-page/404'),
  //         // component: () => import('@/views/data/report/done'),
  //         meta: { title: '已导出报表' }
  //     }]
  // }
  ]
};
export default dataRouter;