var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            staticClass: "news-tag",
            attrs: { src: require("@/assets/tag_imgges/news_icon.png") },
            on: { click: _vm.viewNewsList },
          }),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: "https://qiniu.hywapp.com/hyw-logo.png" },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.modifyPassword },
                      },
                      [_vm._v("修改密码")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("退出")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogFormVisible,
            width: "484px",
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _vm.userInfo.is_first
            ? _c("div", { staticClass: "top-warning" }, [
                _c("i", { staticClass: "el-icon-warning" }),
                _vm._v(" "),
                _c("span", [_vm._v("检测到密码安全性较低，请修改密码")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                {
                  attrs: {
                    type: "flex",
                    justify: "space-between",
                    align: "middle",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", disabled: "" },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.userInfo.is_first && _vm.countdown === 60
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "22px" },
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.timer },
                        },
                        [_vm._v("\n          发送验证码\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.countdown < 60
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-bottom": "22px",
                            "text-align": "left",
                            width: "50px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.countdown) + "s")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.userInfo.is_first
                ? _c(
                    "el-form-item",
                    { attrs: { label: "验证码", prop: "sms_code" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入验证码",
                        },
                        model: {
                          value: _vm.form.sms_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sms_code", $$v)
                          },
                          expression: "form.sms_code",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "旧密码", prop: "old_password" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入旧密码",
                        },
                        model: {
                          value: _vm.form.old_password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "old_password", $$v)
                          },
                          expression: "form.old_password",
                        },
                      }),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", placeholder: "请输入新密码" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "confirm_password" } },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "请再次输入新密码",
                    },
                    model: {
                      value: _vm.form.confirm_password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "confirm_password", $$v)
                      },
                      expression: "form.confirm_password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              !_vm.userInfo.is_first
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }