import Layout from '@/layout';
var contentRouter = {
  path: '/content',
  component: Layout,
  redirect: '/content/share/list',
  name: 'content',
  meta: {
    title: '内容管理',
    icon: 'content'
  },
  children: [{
    path: 'share',
    name: 'contentManageShare',
    meta: {
      title: '就医分享',
      groupTitle: '管理'
    },
    redirect: '/content/share/noteList',
    component: function component() {
      return import('@/views/content/manage/share/index');
    },
    children: [
    //     {
    //     path: 'list',
    //     name: 'contentManageShareList',
    //     component: () => import('@/views/content/manage/share/list'),
    //     meta: { title: '笔记列表' },
    // },
    {
      path: 'noteList',
      name: 'contentManageShareNoteList',
      component: function component() {
        return import('@/views/content/manage/share/note/list');
      },
      meta: {
        title: '就医笔记'
      }
    }, {
      path: 'noteDetail',
      name: 'contentManageShareNoteDetail',
      component: function component() {
        return import('@/views/content/manage/share/note/noteDetail');
      },
      meta: {
        title: '笔记详情'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/content/manage/share/edit');
      },
      name: 'contentManageShareEdit',
      meta: {
        title: '编辑笔记',
        noCache: true,
        activeMenu: '/content/manage/share/list'
      },
      hidden: true
    }, {
      path: 'commentList',
      name: 'contentManageShareCommentList',
      component: function component() {
        return import('@/views/content/manage/share/commentList');
      },
      meta: {
        title: '评论列表'
      }
    }]
  }, {
    path: 'article',
    name: 'contentManageArticle',
    meta: {
      title: '健康科普'
    },
    redirect: '/content/article/articleList',
    alwaysShow: true,
    component: function component() {
      return import('@/views/content/manage/article/index');
    },
    children: [
    //     {
    //     path: 'list',
    //     name: 'contentManageArticleList',
    //     component: () => import('@/views/person/doctor/system/doctorArticle/list'),
    //     meta: { title: '文章列表' },
    // },
    {
      path: 'articleList',
      name: 'contentManagePopSciArticleList',
      component: function component() {
        return import('@/views/content/manage/article/article/list');
      },
      meta: {
        title: '科普文章'
      }
    }, {
      path: 'articleDetail',
      name: 'contentManagePopSciArticleDetail',
      component: function component() {
        return import('@/views/content/manage/article/article/editArticle');
      },
      meta: {
        title: '文章详情'
      },
      hidden: true
    }, {
      path: 'videoList',
      name: 'contentManagePopSciVideoList',
      component: function component() {
        return import('@/views/content/manage/article/video/list');
      },
      meta: {
        title: '科普视频'
      }
    }, {
      path: 'videoDetail',
      name: 'contentManagePopSciVideoDetail',
      component: function component() {
        return import('@/views/content/manage/article/video/editVideo');
      },
      meta: {
        title: '视频详情'
      },
      hidden: true
    }]
  }, {
    path: 'homeRec',
    name: 'contentManageHomeRec',
    component: function component() {
      return import('@/views/content/manage/homeRec/index');
    },
    redirect: '/content/homeRec/list',
    meta: {
      title: '首页推荐'
    },
    children: [{
      path: 'list',
      name: 'contentManageHomeRecList',
      component: function component() {
        return import('@/views/content/manage/homeRec/list');
      },
      meta: {
        title: '列表'
      },
      hidden: true
    }]
  }, {
    path: 'electronicBook',
    name: 'contentManageElectronicBook',
    component: function component() {
      return import('@/views/content/manage/electronicBook/index');
    },
    redirect: '/content/electronicBook/list',
    meta: {
      title: '电子书列表'
    },
    children: [{
      path: 'list',
      name: 'contentManageElectronicBookList',
      component: function component() {
        return import('@/views/content/manage/electronicBook/list');
      },
      meta: {
        title: '列表'
      },
      hidden: true
    }, {
      path: 'create',
      component: function component() {
        return import('@/views/content/manage/electronicBook/create');
      },
      name: 'contentManageElectronicBookCreate',
      meta: {
        title: '添加电子书',
        icon: 'edit'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/content/manage/electronicBook/edit');
      },
      name: 'contentManageElectronicBookEdit',
      meta: {
        title: '编辑电子书',
        noCache: true,
        activeMenu: '/content/manage/electronicBook/list'
      },
      hidden: true
    }]
  }, {
    path: 'callback',
    name: 'contentManageCallback',
    component: function component() {
      return import('@/views/content/manage/callback');
    },
    meta: {
      title: '意见反馈列表'
    }
  }, {
    path: 'inform',
    name: 'contentManageInform',
    meta: {
      title: '举报管理'
    },
    component: function component() {
      return import('@/views/content/manage/inform/index');
    },
    redirect: '/content/inform/list',
    alwaysShow: true,
    children: [{
      path: 'list',
      name: 'contentManageInformList',
      component: function component() {
        return import('@/views/content/manage/inform/list');
      },
      meta: {
        title: '待审核列表'
      }
    }, {
      path: 'result',
      name: 'contentManageInformResult',
      component: function component() {
        return import('@/views/content/manage/inform/result');
      },
      meta: {
        title: '结果查询'
      }
    }]
  }, {
    path: 'announces',
    name: 'contentSettingAnnounces',
    meta: {
      title: '公告中心'
    },
    component: function component() {
      return import('@/views/content/setting/announces/index');
    },
    redirect: '/content/announces/doctor',
    children: [{
      path: 'doctor',
      name: 'contentSettingAnnouncesDoctor',
      component: function component() {
        return import('@/views/content/setting/announces/doctor');
      },
      meta: {
        title: '医生公告列表'
      }
    }, {
      path: 'patients',
      name: 'contentSettingAnnouncesPatients',
      component: function component() {
        return import('@/views/content/setting/announces/patients');
      },
      meta: {
        title: '患者公告列表'
      }
    }, {
      path: 'versions',
      name: 'contentSettingAnnouncesVersions',
      meta: {
        title: '版本通知列表'
      },
      component: function component() {
        return import('@/views/content/setting/announces/versions/index');
      },
      redirect: '/content/announces/versions/list',
      children: [{
        path: 'list',
        name: 'contentSettingAnnouncesVersionsList',
        component: function component() {
          return import('@/views/content/setting/announces/versions/list');
        },
        meta: {
          title: '列表'
        },
        hidden: true
      }, {
        path: 'create/:id(\\d+)',
        component: function component() {
          return import('@/views/content/setting/announces/versions/create');
        },
        name: 'contentSettingAnnouncesVersionsCreate',
        meta: {
          title: '新增版本',
          icon: 'edit'
        },
        hidden: true
      }]
    }]
  },
  // {
  //     path: 'material',
  //     name: 'contentManageMaterial',
  //     meta: { title: '素材管理' },
  //     component: () => import('@/views/content/manage/material/index'),
  //     redirect: '/content/manage/material/video',
  //     children: [{
  //         path: 'video',
  //         name: 'contentManageMaterialVideo',
  //         component: () => import('@/views/error-page/404'),
  //         meta: { title: '视频' },
  //     },{
  //         path: 'photo',
  //         name: 'contentManageMaterialPhoto',
  //         component: () => import('@/views/error-page/404'),
  //         meta: { title: '图片' },
  //     }]
  // },
  {
    path: 'sensitiveWord',
    name: 'contentSettingSensitiveWord',
    component: function component() {
      return import('@/views/content/setting/sensitiveWord');
    },
    meta: {
      title: '敏感词列表',
      groupTitle: '设置'
    }
  }, {
    path: 'noteTagSetting',
    name: 'noteTagSetting',
    component: function component() {
      return import('@/views/content/setting/noteConfigTag');
    },
    meta: {
      title: '就医分享配置'
    }
  }]
};
export default contentRouter;