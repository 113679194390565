//
//
//
//

export default {
  props: {
    type: {
      // 'success': 完成、成功：绿色
      // 'worning': 删除、禁止、终止、失败：红色
      // 'theme': 一般、进行中、需要确认：蓝色
      // 'orange': 中间状态：橙色
      // 'info': 取消、失效、无需关注的完成：灰色
      type: String,
      default: ''
    }
  }
};