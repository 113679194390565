import Layout from '@/layout';
var merchantRouter = {
  path: '/merchant',
  component: Layout,
  redirect: '/merchant/merchantCenter',
  name: 'merchant',
  meta: {
    title: '商家中心',
    icon: 'marketing'
  },
  alwaysShow: true,
  children: [{
    path: 'merchantCenter',
    name: 'merchantCenter',
    meta: {
      title: '合作商家列表',
      groupTitle: '商家管理'
    },
    redirect: '/merchant/merchantCenter/merchantList',
    component: function component() {
      return import('@/views/marketing/activity/index');
    },
    children: [{
      path: 'merchantList',
      name: 'merchantList',
      component: function component() {
        return import('@/views/merchant/merchant/cooperate/merchantList');
      },
      meta: {
        title: '合作商家列表'
      }
    }]
  }, {
    path: 'merchantSettled',
    name: 'merchantSettled',
    component: function component() {
      return import('@/views/merchant/merchant/cooperate/merchantSettled');
    },
    meta: {
      title: '商家入驻'
    },
    hidden: true
  }, {
    path: 'merchantQuaReview',
    name: 'merchantQuaReview',
    component: function component() {
      return import('@/views/merchant/merchant/cooperate/merchantQuaReview');
    },
    meta: {
      title: '资质审核'
    },
    hidden: true
  }, {
    path: 'merchantSignReview',
    name: 'merchantSignReview',
    component: function component() {
      return import('@/views/merchant/merchant/cooperate/merchantSignReview');
    },
    meta: {
      title: '签约审核'
    },
    hidden: true
  }, {
    path: 'merchantBrandManage',
    name: 'merchantBrandManage',
    component: function component() {
      return import('@/views/merchant/merchant/brand/merchantBrandManage');
    },
    meta: {
      title: '商家品牌管理'
    }
  }, {
    path: 'merchantBrandReview',
    name: 'merchantBrandReview',
    component: function component() {
      return import('@/views/merchant/merchant/brand/merchantBrandReview');
    },
    meta: {
      title: '商家品牌审核'
    },
    hidden: true
  }, {
    path: 'merchantBrandList',
    name: 'merchantBrandList',
    component: function component() {
      return import('@/views/merchant/merchant/brand/merchantInfoList');
    },
    meta: {
      title: '商家资料审核'
    }
  }]
};
export default merchantRouter;