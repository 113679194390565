import "core-js/modules/es6.regexp.replace";
import BrowserLogger from 'alife-logger';
var logger = function () {
  var __bl = BrowserLogger.singleton({
    pid: "hgus6xj3qv@d6d53c6ea74bed9",
    appType: "web",
    imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
    sendResource: true,
    enableLinkTrace: true,
    environment: process.env.NODE_ENV == 'production' ? 'prod' : 'daily',
    behavior: true,
    enableSPA: true,
    parseHash: function parseHash(hash) {
      return hash ? hash.replace(/^#/, '').replace(/\?.*$/, '') : '';
    }
  });
}();
export default logger;