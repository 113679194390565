import Layout from '@/layout';
var personRouter = {
  path: '/person',
  component: Layout,
  redirect: '/person/apply/list',
  name: 'person',
  meta: {
    title: '人员管理',
    icon: 'person'
  },
  children: [{
    path: 'system',
    name: 'personDoctorSystem',
    meta: {
      title: '系统医生',
      groupTitle: '医生'
    },
    redirect: '/person/system/list',
    component: function component() {
      return import('@/views/person/doctor/system/index');
    },
    alwaysShow: true,
    children: [{
      path: 'create',
      component: function component() {
        return import('@/views/person/doctor/system/create');
      },
      name: 'personDoctorSystemCreate',
      meta: {
        title: '添加医生'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/person/doctor/system/edit');
      },
      name: 'personDoctorSystemEdit',
      meta: {
        title: '编辑医生',
        noCache: true,
        activeMenu: '/person/doctor/system/list'
      },
      hidden: true
    }, {
      path: 'list',
      name: 'personDoctorSystemList',
      component: function component() {
        return import('@/views/person/doctor/system/list');
      },
      meta: {
        title: '医生列表'
      }
    }, {
      path: 'jobTitle',
      name: 'personDoctorSystemJobTitle',
      component: function component() {
        return import('@/views/person/doctor/system/jobTitle');
      },
      meta: {
        title: '医生职称'
      }
    }, {
      path: 'tag',
      name: 'personDoctorSystemTag',
      component: function component() {
        return import('@/views/person/doctor/system/tag');
      },
      meta: {
        title: '医生印象标签'
      },
      hidden: true
    }, {
      path: 'articleCreate',
      component: function component() {
        return import('@/views/person/doctor/system/doctorArticle/create');
      },
      name: 'allDoctorArticleCreate',
      meta: {
        title: '添加文章',
        icon: 'edit'
      },
      hidden: true
    }, {
      path: 'articleEdit/:id(\\d+)',
      component: function component() {
        return import('@/views/person/doctor/system/doctorArticle/edit');
      },
      name: 'allDoctorArticleEdit',
      meta: {
        title: '编辑医生文章',
        noCache: true,
        activeMenu: '/doctor/doctorArticle'
      },
      hidden: true
    }, {
      path: 'registerList',
      name: 'registerDoctorSystemList',
      component: function component() {
        return import('@/views/person/doctor/register/list');
      },
      meta: {
        title: '挂号医生列表'
      }
    }]
  }, {
    path: 'apply',
    name: 'personDoctorSystemApply',
    redirect: '/person/apply/list',
    component: function component() {
      return import('@/views/person/doctor/apply/index');
    },
    meta: {
      title: '签约医生'
    },
    children: [{
      path: 'list',
      name: 'personDoctorSystemApplyList',
      component: function component() {
        return import('@/views/person/doctor/apply/list');
      },
      meta: {
        title: '医生列表'
      }
    }, {
      path: 'doctorDetail',
      name: 'personDoctorSystemApplyDoctorDetail',
      component: function component() {
        return import('@/views/person/doctor/apply/doctorDetail');
      },
      meta: {
        title: '医生详情'
      },
      hidden: true
    }, {
      path: 'edit/:id(\\d+)',
      component: function component() {
        return import('@/views/person/doctor/apply/edit');
      },
      name: 'personDoctorSystemApplyEdit',
      meta: {
        title: '编辑医生',
        noCache: true,
        activeMenu: '/person/doctor/apply/list'
      },
      hidden: true
    }, {
      path: 'attestation/:id(\\d+)',
      component: function component() {
        return import('@/views/person/doctor/apply/attestation');
      },
      name: 'personDoctorSystemApplyAttestation',
      meta: {
        title: '认证医生',
        noCache: true,
        activeMenu: '/person/doctor/apply/list'
      },
      hidden: true
    }, {
      path: 'commissionerList',
      name: 'personDoctorSystemApplyCommissionerList',
      component: function component() {
        return import('@/views/person/doctor/apply/commissioner/index');
      },
      meta: {
        title: '专员列表'
      }
    }]
  }, {
    path: 'list',
    name: 'personPatientsList',
    component: function component() {
      return import('@/views/person/patients/list');
    },
    meta: {
      title: '患者列表',
      groupTitle: "患者"
    }
  }, {
    path: 'edit/:id',
    component: function component() {
      return import('@/views/person/patients/patientDetail');
    },
    name: 'patientsEdit',
    meta: {
      title: '患者详情',
      noCache: true,
      activeMenu: '/person/patients/list'
    },
    hidden: true
  }, {
    path: 'registList',
    name: 'personRegisterList',
    redirect: '/person/registList/regist',
    component: function component() {
      return import('@/views/person/register/index');
    },
    meta: {
      title: '用户',
      groupTitle: "用户"
    },
    children: [{
      path: 'regist',
      name: 'personRegisterListRegist',
      component: function component() {
        return import('@/views/person/register/list');
      },
      meta: {
        title: '注册用户列表'
      }
    }, {
      path: 'edit/:id',
      component: function component() {
        return import('@/views/person/register/registerDetail');
      },
      name: 'registerEdit',
      meta: {
        title: '用户详情',
        noCache: true,
        activeMenu: '/person/registList/regist'
      },
      hidden: true
    }]
  },
  // {
  //     path: 'blackList',
  //     name: 'personRegisterBlackList',
  //     component: () => import('@/views/person/register/blackList'),
  //     meta: { title: '黑名单' },
  // },
  {
    path: 'manage',
    name: 'personManage',
    redirect: '/person/manage/blackList',
    component: function component() {
      return import('@/views/person/manage/index');
    },
    meta: {
      title: '管理'
    },
    children: [{
      path: 'blackList',
      name: 'personRegisterBlackList',
      component: function component() {
        return import('@/views/person/manage/blackList');
      },
      meta: {
        title: '黑名单'
      }
    }, {
      path: 'userTag',
      name: 'registerUserTag',
      component: function component() {
        return import('@/views/person/manage/userTag');
      },
      meta: {
        title: '用户标签'
      }
    }, {
      path: 'grouping',
      name: 'personRegisterListGrouping',
      component: function component() {
        return import('@/views/person/register/grouping/index');
      },
      meta: {
        title: '用户分群'
      }
    }, {
      path: 'groupingDetail',
      name: 'personRegisterListGroupingDetail',
      component: function component() {
        return import('@/views/person/register/grouping/groupingDetail');
      },
      meta: {
        title: '已有优惠券待转化用户'
      },
      hidden: true
    }]
  }]
};
export default personRouter;