import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
function hasServePermission(rolesview, route) {
  if (route.name) {
    return rolesview.includes(route.name);
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, rolesView) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasServePermission(rolesView, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, rolesView);
      }
      res.push(tmp);
    }
  });
  return res;
}
function simpleAsyncRoutes(routes) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = {
      "id": route.name,
      "pid": route.name,
      "name": route.name,
      "title": route.meta ? route.meta.title : undefined,
      "children": route.children
    };
    if (tmp.children) {
      tmp.children = simpleAsyncRoutes(tmp.children);
    }
    res.push(tmp);
  });
  return res;
}
var state = {
  routes: constantRoutes,
  asyncRoutes: simpleAsyncRoutes(asyncRoutes),
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = [routes];
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    var rolesList = roles['roles'];
    var viewsList = roles['views'];
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (rolesList.includes('admin')) {
        var _accessedRoutes;
        accessedRoutes = asyncRoutes || [];
        // 过滤掉店铺管理
        accessedRoutes = (_accessedRoutes = accessedRoutes) === null || _accessedRoutes === void 0 ? void 0 : _accessedRoutes.filter(function (route) {
          return route.name !== 'merchantStore';
        });
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, viewsList);
      }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};