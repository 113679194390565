exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#0A2A4D",
	"menuActiveText": "#0081FF",
	"subMenuActiveText": "#0081FF",
	"menuBg": "#fff",
	"menuHover": "#E6EAF0",
	"subMenuBg": "#EFF1F4",
	"subMenuHover": "#E6EAF0",
	"sideBarWidth": "210px"
};